import { Item } from "./allCupItems";

const shuffleCupItems = (itemsArray: Item[]): Item[] => {
  const shuffledItems = [...itemsArray];

  for (
    let currentIndex = shuffledItems.length - 1;
    currentIndex > 0;
    currentIndex--
  ) {
    // Generate a random index between 0 and currentIndex (inclusive)
    const randomIndex = Math.floor(Math.random() * (currentIndex + 1));

    // Swap the elements at currentIndex and randomIndex
    [shuffledItems[currentIndex], shuffledItems[randomIndex]] = [
      shuffledItems[randomIndex],
      shuffledItems[currentIndex],
    ];
  }

  return shuffledItems;
};

export default shuffleCupItems;
