export const saveAttemptHistory = (
  attemptHistory: { order: number[]; correctPositions: number }[]
) => {
  localStorage.setItem("attemptHistory", JSON.stringify(attemptHistory));
};

export const loadAttemptHistory = (): {
  order: number[];
  correctPositions: number;
}[] => {
  const savedAttemptHistory = localStorage.getItem("attemptHistory");
  return savedAttemptHistory ? JSON.parse(savedAttemptHistory) : [];
};
