import React, { useEffect, useState } from "react";
import "./Countdown.css";

interface CountdownProps {
  gameOver: boolean;
}

const Countdown: React.FC<CountdownProps> = ({ gameOver }) => {
  const [countdown, setCountdown] = useState<number>(0);
  const [countdownFinished, setCountdownFinished] = useState<boolean>(false);

  useEffect(() => {
    if (!gameOver) return; // Exit early if game is not over

    // Get current time in UTC
    const currentTime = new Date();
    const currentUTC = Date.UTC(
      currentTime.getUTCFullYear(),
      currentTime.getUTCMonth(),
      currentTime.getUTCDate(),
      0, // Hours
      0, // Minutes
      0, // Seconds
      0 // Milliseconds
    );

    // Calculate time until UTC 0 (midnight)
    const timeUntilUTC0 = currentUTC + 24 * 60 * 60 * 1000 - Date.now();

    // Update countdown state with time until UTC 0
    setCountdown(timeUntilUTC0);

    // Update countdown every second
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1000) {
          setCountdownFinished(true);
          clearInterval(interval);
          return 0;
        }
        return prevCountdown - 1000;
      });
    }, 1000);

    // Clear interval when component unmounts
    return () => clearInterval(interval);
  }, [gameOver]);

  // Convert milliseconds to hours, minutes, and seconds
  const hours = Math.floor((countdown / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((countdown / (1000 * 60)) % 60);
  const seconds = Math.floor((countdown / 1000) % 60);

  return (
    <>
      {gameOver && (
        <div className="countdown-container">
          <div className="countdown-text">
            Next <span className="cupller-span">Cupller</span>
          </div>
          <p className="countdown-timer">
            {hours.toString().padStart(2, "0")} :{" "}
            {minutes.toString().padStart(2, "0")} :{" "}
            {seconds.toString().padStart(2, "0")}
          </p>
          {countdownFinished && (
            <div className="refresh-latest">
              Please refresh the page for the latest Cupller
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Countdown;
