import blueCupSvg from "../assets/blue-cup.svg";
import greenCupSvg from "../assets/green-cup.svg";
import orangeCupSvg from "../assets/orange-cup.svg";
import purpleCupSvg from "../assets/purple-cup.svg";
import redCupSvg from "../assets/red-cup.svg";
import yellowCupSvg from "../assets/yellow-cup.svg";

import hcBlueCupSvg from "../assets/hc/hc-blue-cup.svg";
import hcGreenCupSvg from "../assets/hc/hc-green-cup.svg";
import hcOrangeCupSvg from "../assets/hc/hc-orange-cup.svg";
import hcPurpleCupSvg from "../assets/hc/hc-purple-cup.svg";
import hcRedCupSvg from "../assets/hc/hc-red-cup.svg";
import hcYellowCupSvg from "../assets/hc/hc-yellow-cup.svg";

export interface Item {
  id: number;
  content: string;
}

const allCupItems: Item[] = [
  { id: 1, content: redCupSvg },
  { id: 2, content: orangeCupSvg },
  { id: 3, content: yellowCupSvg },
  { id: 4, content: greenCupSvg },
  { id: 5, content: blueCupSvg },
  { id: 6, content: purpleCupSvg },
];

const allHighContrastCupItems: Item[] = [
  { id: 1, content: hcRedCupSvg },
  { id: 2, content: hcOrangeCupSvg },
  { id: 3, content: hcYellowCupSvg },
  { id: 4, content: hcGreenCupSvg },
  { id: 5, content: hcBlueCupSvg },
  { id: 6, content: hcPurpleCupSvg },
];

export { allCupItems, allHighContrastCupItems };

