import { Item } from "./allCupItems";
import { loadAttemptHistory, saveAttemptHistory } from "./attemptHistory";
import { getMaxNumberOfTries } from "./gameConfig";

// Hash a string
const hashString = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32-bit integer
  }
  return hash;
};

// Seeded random function
const seededRandom = (seed: number) => {
  const x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
};

// Shuffle with a seed
const shuffleWithSeed = (items: Item[], seed: number): Item[] => {
  const shuffledItems = [...items];
  for (let i = shuffledItems.length - 1; i > 0; i--) {
    const j = Math.floor(seededRandom(seed + i) * (i + 1));
    [shuffledItems[i], shuffledItems[j]] = [shuffledItems[j], shuffledItems[i]];
  }
  return shuffledItems;
};

const getDailySolutionSequence = (allItems: Item[]): Item[] => {
  const today = new Date().toISOString().split("T")[0]; // Get current date as YYYY-MM-DD
  // const todayUTC = new Date(); // Get current date in UTC
  // const todayUTCPlus3 = new Date(todayUTC.getTime() + 88 * 60 * 60 * 1000); // UTC+3
  // const today = todayUTCPlus3.toISOString().split("T")[0];

  const seed = hashString(today);

  const shuffledItems = shuffleWithSeed(allItems, seed);
  return shuffledItems.slice(0, 5); // Return only the first 5 items
};

const saveDailySolution = (solutionIds: number[]) => {
  const today = new Date().toISOString().split("T")[0];
  // const todayUTC = new Date();
  // const todayUTCPlus3 = new Date(todayUTC.getTime() + 88 * 60 * 60 * 1000); // UTC+3
  // const today = todayUTCPlus3.toISOString().split("T")[0];

  localStorage.setItem("dailySolutionDate", today);
  localStorage.setItem("dailySolution", JSON.stringify(solutionIds));
};

const loadDailySolution = (
  allItems: Item[]
): { solution: Item[]; isNew: boolean } => {
  const today = new Date().toISOString().split("T")[0];
  // const todayUTC = new Date(); // Get current date in UTC
  // const todayUTCPlus3 = new Date(todayUTC.getTime() + 88 * 60 * 60 * 1000); // UTC+3
  // const today = todayUTCPlus3.toISOString().split("T")[0];

  const savedDate = localStorage.getItem("dailySolutionDate");
  const savedSolution = localStorage.getItem("dailySolution");

  if (savedDate === today && savedSolution) {
    const solutionIds: number[] = JSON.parse(savedSolution);
    const solutionItems: Item[] = solutionIds.map(
      (id) => allItems.find((item) => item.id === id)!
    );
    if (solutionItems.some((item) => item === undefined)) {
      // Handle case where some items are undefined
      const newSolutionItems = getDailySolutionSequence(allItems);
      const newSolutionIds = newSolutionItems.map((item) => item.id);
      saveDailySolution(newSolutionIds);
      saveAttemptHistory([]);
      // Clear attempts if the player hasn't finished the game
      const attemptHistory = loadAttemptHistory();
      const maxAttempts = getMaxNumberOfTries();
      const finishedGame = attemptHistory.length >= maxAttempts;
      if (!finishedGame) {
        saveAttemptHistory([]);
      }
      return { solution: newSolutionItems, isNew: true };
    }
    return { solution: solutionItems, isNew: false };
  } else {
    const newSolutionItems = getDailySolutionSequence(allItems);
    const newSolutionIds = newSolutionItems.map((item) => item.id);
    saveDailySolution(newSolutionIds);

    // Clear attempts if the player hasn't finished the game
    const attemptHistory = loadAttemptHistory();
    const maxAttempts = getMaxNumberOfTries();
    const finishedGame = attemptHistory.length >= maxAttempts;
    if (!finishedGame) {
      saveAttemptHistory([]);
    }
    return { solution: newSolutionItems, isNew: true };
  }
};

export { getDailySolutionSequence, loadDailySolution };

