import React from "react";
import "./Consent.css";

interface ModalProps {
  onClose: () => void;
}

const Consent: React.FC<ModalProps> = ({ onClose }) => {
  const handleOkClick = () => {
    localStorage.setItem("consent", "1");
    onClose();
  };

  return (
    <div className="modal-overlay-con">
      <div className="modal-con">
        <div className="modal-content-con">
          <div className="cupller-title">Cupller</div>
          <h2>PRIVACY NOTICE</h2>
          <p>
            This application uses local storage to store data that does not
            identify you personally. All information is stored locally on your
            device and is not shared with any third parties. This data is
            necessary for the application to run.
          </p>
          <p>
            By clicking <span>OK</span>, you consent to the use of local storage
            for storing application data.
          </p>
          <button className="ok-btn" onClick={handleOkClick}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default Consent;
