import React from "react";
import { Item } from "../utils/allCupItems";

interface SolutionSequenceProps {
  dailySolutionSequence: Item[];
}

const SolutionSequence: React.FC<SolutionSequenceProps> = ({
  dailySolutionSequence,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {dailySolutionSequence.map((item) => (
        <img
          key={item.id}
          src={item.content}
          alt={`Cup ${item.id}`}
          style={{ width: "50px", height: "50px", margin: "0 5px" }}
        />
      ))}
    </div>
  );
};

export default SolutionSequence;
