import React from "react";
import "./DistributionChart.css";

interface DistributionChartProps {
  winningDistribution: number[];
  lastGuessIndex: number;
}

const DistributionChart: React.FC<DistributionChartProps> = ({
  winningDistribution,
  lastGuessIndex,
}) => {
  const maxCount = Math.max(...winningDistribution);
  const barMinWidth = 50; // Minimum width for bars with 0 count

  const getResponsiveStyles = (
    isLastGuess: boolean,
    barMinWidth: number,
    barWidth: string
  ) => {
    const baseStyles = {
      backgroundColor: isLastGuess
        ? "var(--secondary-text)"
        : "var(--primary-text)",
      color: "var(--bg-color)",
      fontFamily: `"Nunito Sans", sans-serif`,
      fontWeight: "var(--nunito-bold)",
      justifyContent: "center",
      alignItems: "center",
      minWidth: `${barMinWidth}px`,
      width: barWidth,
      maxWidth: "calc(100% - 50px)",
      textAlign: "center" as const,
    };

    if (window.innerWidth <= 700) {
      return {
        ...baseStyles,
        padding: "0px 0",
      };
    } else {
      return {
        ...baseStyles,
        padding: "5px 0",
      };
    }
  };

  return (
    <div className="distribution-container">
      <div className="distribution-title">WIN DISTRIBUTION</div>
      <div>
        {winningDistribution.map((count, index) => {
          const barWidthPercentage =
            maxCount > 0 ? (count / maxCount) * 100 : 0;
          const barWidth =
            barWidthPercentage > 0
              ? `${barWidthPercentage}%`
              : `${barMinWidth}px`;
          // Check if the current bar is the most recent successful guess
          const isLastGuess = index === lastGuessIndex;

          return (
            <div className="bars-container" key={index}>
              <span className="bars-span">{index + 1} </span>
              <div
                style={getResponsiveStyles(isLastGuess, barMinWidth, barWidth)}
              >
                {count}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DistributionChart;
