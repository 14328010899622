import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import "./SortableItem.css";

interface Item {
  id: number;
  content: string;
  animate: boolean;
  isSelected: boolean; // Add isSelected prop
}

const SortableItem: React.FC<Item> = ({ id, content, animate, isSelected }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const index = id - 1; // Adjust index to start from 0
  const animationDelay = animate ? `${index * 100}ms` : "0ms";

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: "100px",
    height: "100px",
    cursor: "grab",
    userSelect: "none" as "none",
    animationDelay,
  };

  // Conditionally apply the bounce class based on the animate prop
  const itemClassName = `sortable-item ${animate ? "bounce" : ""} ${
    isSelected ? "bounce-effect" : ""
  }`;

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={itemClassName}
      {...attributes}
      {...listeners}
    >
      <img src={content} alt={`Cup ${id}`} />
    </div>
  );
};

export default SortableItem;
