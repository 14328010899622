import React, { useEffect, useState } from "react";
import { toggleBodyOverflow } from "../../../utils/toggleBodyOverflow";
import Countdown from "../countdown/Countdown";
import DistributionChart from "../distributionChart/DistributionChart";
import "./StatisticsModal.css";

export interface GameStatisticsType {
  totalGames: number;
  gamesWon: number;
  winRate: number;
  averageScore: number;
}

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  gameStatistics: GameStatisticsType;
  winningDistribution: number[];
  gameOver: boolean;
  lastGuessIndex: number;
}

const StatisticsModal: React.FC<IModalProps> = ({
  isOpen,
  onClose,
  gameStatistics,
  winningDistribution,
  gameOver,
  lastGuessIndex,
}) => {
  const [isClickToCopyVisible, setIsClickToCopyVisible] =
    useState<boolean>(false);

  useEffect(() => {
    toggleBodyOverflow(isOpen);
    return () => toggleBodyOverflow(false);
  }, [isOpen]);

  useEffect(() => {
    if (isClickToCopyVisible) {
      const timeout = setTimeout(() => {
        setIsClickToCopyVisible(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [isClickToCopyVisible]);

  if (!isOpen) {
    return null;
  }

  // Format date from yyyy-mm-dd to dd-mm-yyyy
  const formatDate = (dateString: string) => {
    if (!dateString) return ""; // Return an empty string if the dateString is null or empty
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  // Retrieve attempt history and daily solution date from local storage
  function getAttemptHistoryAndDate() {
    const attemptHistory = JSON.parse(
      localStorage.getItem("attemptHistory") || "[]"
    );
    const dailySolutionDate = localStorage.getItem("dailySolutionDate") || "";
    return { attemptHistory, dailySolutionDate };
  }

  // Create a shareable message based on the attempt history and other relevant information
  const createShareMessage = (
    attemptHistory: { order: number[]; correctPositions: number }[],
    dailySolutionDate: string
  ) => {
    const formattedDate = formatDate(dailySolutionDate);
    const dailySolutionSequence: number[] = JSON.parse(
      localStorage.getItem("dailySolution") || "[]"
    );

    const mappedAttempts = attemptHistory
      .map((attempt, index) => {
        const mappedOrder = attempt.order
          .map((id, idx) => {
            const isMatched = id === dailySolutionSequence[idx];
            return isMatched ? "✅" : "❌";
          })
          .join(" ");
        return `${index + 1}: ${mappedOrder} - ${
          attempt.correctPositions
        } matched cups`;
      })
      .join("\n");

    return `Cupller ${formattedDate}\n\n${mappedAttempts}\n\n`;
  };

  const { attemptHistory, dailySolutionDate } = getAttemptHistoryAndDate();
  const textToShare = createShareMessage(attemptHistory, dailySolutionDate);

  const shareUrl = window.location.origin;
  const shareTitle = "Cupller";
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isFirefox = /Firefox/i.test(navigator.userAgent);

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const handleCopyToClipboard = () => {
    // Focus the document
    window.focus();
  
    // Use a timeout to ensure the document is focused before attempting to write to the clipboard
    setTimeout(() => {
      navigator.clipboard.writeText(textToShare).then(() => {
        setIsClickToCopyVisible(true);
      }).catch((err) => {
        console.error("Failed to copy text: ", err);
      });
    }, 100);
  };

  const handleShare = async () => {
    try {
      if (navigator.share && !isFirefox) {
        await navigator.share({
          title: shareTitle,
          text: textToShare,
          url: shareUrl,
        });
      } else {
        handleCopyToClipboard();
      }
    } catch (error) {
      console.error("Error sharing:", error);
      handleCopyToClipboard();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal">
        <button className="x-button" onClick={onClose}>
          X
        </button>
        <div className="modal-content">
          <h2>STATISTICS</h2>
          <div className="statistics">
            <div className="statistic">
              <p>{gameStatistics.totalGames}</p>
              <p>Played</p>
            </div>
            <div className="statistic">
              <p>{gameStatistics.gamesWon}</p>
              <p>Won</p>
            </div>
            <div className="statistic">
              <p>{gameStatistics.winRate}%</p>
              <p>Win Rate</p>
            </div>
            <div className="statistic">
              <p>{gameStatistics.averageScore}</p>
              <p>Avg Win Score</p>
            </div>
          </div>

          <DistributionChart
            winningDistribution={winningDistribution}
            lastGuessIndex={lastGuessIndex}
          />

          <div className="bottom-section">
            <Countdown gameOver={gameOver} />
            {gameOver && (
              <>
                {isMobile ? (
                  <button className="share-btn" onClick={handleShare}>
                    Share
                  </button>
                ) : (
                  <button className="share-btn" onClick={handleCopyToClipboard}>
                    Share
                  </button>
                )}
              </>
            )}
          </div>

          <div
            className={`text-container ${
              isClickToCopyVisible ? "show" : "hide"
            }`}
          >
            <p>Copied!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsModal;
