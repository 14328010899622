import React, { useEffect, useState } from "react";
import { toggleBodyOverflow } from "../../../utils/toggleBodyOverflow";
import "./SettingsModal.css";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  toggleTheme: () => void;
  isLightTheme: boolean;
  toggleGameMode: () => void;
  isHardMode: boolean;
  isHighContrast: boolean;
  toggleHighContrastMode: () => void;
}

const SettingsModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  toggleTheme,
  isLightTheme,
  toggleGameMode,
  isHardMode,
  isHighContrast,
  toggleHighContrastMode,
}) => {
  const [disableHardModeToggle, setDisableHardModeToggle] = useState(false);
  const [toggleMessage, setToggleMessage] = useState<string>("");
  useEffect(() => {
    toggleBodyOverflow(isOpen);

    // Check localStorage for attemptHistory
    const attemptHistory = localStorage.getItem("attemptHistory");
    if (attemptHistory) {
      setDisableHardModeToggle(true); // Disable hard mode toggle if attemptHistory exists
    } else {
      setDisableHardModeToggle(false);
    }

    return () => toggleBodyOverflow(false);
  }, [isOpen]);

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  const handleToggleGameMode = () => {
    if (disableHardModeToggle) {
      setToggleMessage(
        "Hard mode can only be enabled or disabled at the start of the game, when no attempts have been made."
      );
      setTimeout(() => {
        setToggleMessage("");
      }, 5000);
    } else {
      toggleGameMode();
    }
  };

  return (
    <div className="modal-overlay-set" onClick={handleOverlayClick}>
      <div className="modal-set">
        <button className="x-button" onClick={onClose}>
          X
        </button>
        <div className="modal-content-set">
          <h2>SETTINGS</h2>
        </div>

        <div className="theme-setup">
          <div className="theme-setup-container">
            <div className="theme-setup-main-text">Light mode</div>
            <label className="theme-toggle">
              <input
                type="checkbox"
                checked={isLightTheme}
                onChange={toggleTheme}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="theme-setup-secondary-text">
            Toggle between Dark mode and Light mode
          </div>
        </div>

        <div className="game-setup">
          <div className="game-setup-container">
            <div className="game-setup-main-text">Hard mode</div>
            <label className="game-toggle">
              <input
                type="checkbox"
                checked={isHardMode}
                onChange={handleToggleGameMode}
                disabled={toggleMessage !== ""}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="game-setup-secondary-text">
            Guess the cup sequence in 5 attempts
          </div>
          {toggleMessage && (
            <div className="toggle-message">{toggleMessage}</div>
          )}
        </div>

        <div className="contrast-setup">
          <div className="contrast-setup-container">
            <div className="contrast-setup-main-text">High contrast mode</div>
            <label className="contrast-toggle">
              <input
                type="checkbox"
                checked={isHighContrast}
                onChange={toggleHighContrastMode}
              />
              <span className="slider round"></span>
            </label>
          </div>

          <div className="contrast-setup-secondary-text">
            Improves color clarity for color blindness
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
