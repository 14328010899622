// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countdown-container {
  margin-top: 40px;
}

.countdown-text {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.8rem;
  color: var(--primary-text);
  cursor: default;
  font-weight: var(--nunito-bold);
  margin-top: 30px;
}

.countdown-timer {
  font-size: 1.8rem;
  font-weight: var(--nunito-black);
}

.cupller-span {
  font-family: "Leckerli One", cursive;
  font-size: 2rem;
  color: var(--primary-text);
}

.refresh-latest {
  font-size: 1rem;
  color: var(--secondary-text);
  text-align: center;
}

@media screen and (max-width: 700px) {
  .countdown-container {
    margin-top: 10px;
  }

  .countdown-text {
    font-size: 1.3rem;
    margin-top: 10px;
  }

  .cupller-span {
    font-size: 1.7rem;
  }

  .countdown-timer {
    font-size: 1.3rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/countdown/Countdown.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;EACtC,iBAAiB;EACjB,0BAA0B;EAC1B,eAAe;EACf,+BAA+B;EAC/B,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,gCAAgC;AAClC;;AAEA;EACE,oCAAoC;EACpC,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".countdown-container {\r\n  margin-top: 40px;\r\n}\r\n\r\n.countdown-text {\r\n  font-family: \"Nunito Sans\", sans-serif;\r\n  font-size: 1.8rem;\r\n  color: var(--primary-text);\r\n  cursor: default;\r\n  font-weight: var(--nunito-bold);\r\n  margin-top: 30px;\r\n}\r\n\r\n.countdown-timer {\r\n  font-size: 1.8rem;\r\n  font-weight: var(--nunito-black);\r\n}\r\n\r\n.cupller-span {\r\n  font-family: \"Leckerli One\", cursive;\r\n  font-size: 2rem;\r\n  color: var(--primary-text);\r\n}\r\n\r\n.refresh-latest {\r\n  font-size: 1rem;\r\n  color: var(--secondary-text);\r\n  text-align: center;\r\n}\r\n\r\n@media screen and (max-width: 700px) {\r\n  .countdown-container {\r\n    margin-top: 10px;\r\n  }\r\n\r\n  .countdown-text {\r\n    font-size: 1.3rem;\r\n    margin-top: 10px;\r\n  }\r\n\r\n  .cupller-span {\r\n    font-size: 1.7rem;\r\n  }\r\n\r\n  .countdown-timer {\r\n    font-size: 1.3rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
