import React, { useEffect, useState } from "react";
import howToPlayLightIcon from "../../assets/how-to-play-light.svg";
import howToPlayIcon from "../../assets/how-to-play.svg";
import settingsLightIcon from "../../assets/settings-light.svg";
import settingsIcon from "../../assets/settings.svg";
import statisticsLightIcon from "../../assets/statistics-light.svg";
import statisticsIcon from "../../assets/statistics.svg";
import HowToPlayModal from "../modals/howToPlay/HowToPlay";
import SettingsModal from "../modals/settingsModal/SettingsModal";
import StatisticsModal, {
  GameStatisticsType,
} from "../modals/statisticsModal/StatisticsModal";
import "./Header.css";

interface HeaderProps {
  gameStatistics: GameStatisticsType;
  winningDistribution: number[];
  gameOver: boolean;
  lastGuessIndex: number;
  resetSelectedItem: () => void;
  toggleGameMode: () => void;
  isHardMode: boolean;
  isHighContrast: boolean;
  toggleHighContrastMode: () => void;
}

const defaultColors = [
  "--red-color",
  "--orange-color",
  "--yellow-color",
  "--green-color",
  "--blue-color",
  "--purple-color",
];

const highContrastColors = [
  "--red-hc-color",
  "--orange-hc-color",
  "--yellow-hc-color",
  "--green-hc-color",
  "--blue-hc-color",
  "--purple-hc-color",
];

const Header: React.FC<HeaderProps> = ({
  gameStatistics,
  winningDistribution,
  gameOver,
  lastGuessIndex,
  resetSelectedItem,
  toggleGameMode,
  isHardMode,
  isHighContrast,
  toggleHighContrastMode,
}) => {
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      return savedTheme === "light";
    } else {
      localStorage.setItem("theme", "dark");
      return false;
    }
  };

  const [showStatisticsModal, setShowStatisticsModal] = useState(false);
  const [showHowToPlayModal, setShowHowToPlayModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [colors, setColors] = useState(
    isHighContrast ? highContrastColors : defaultColors
  );

  const [isLightTheme, setIsLightTheme] = useState(getInitialTheme);

  useEffect(() => {
    if (isLightTheme) {
      document.body.classList.add("light-theme");
      localStorage.setItem("theme", "light");
    } else {
      document.body.classList.remove("light-theme");
      localStorage.setItem("theme", "dark");
    }
  }, [isLightTheme]);

  useEffect(() => {
    setColors(isHighContrast ? highContrastColors : defaultColors);
  }, [isHighContrast]);

  const handleStatisticsClick = () => {
    setShowStatisticsModal(true);
  };

  const handleCloseModal = () => {
    setShowStatisticsModal(false);
  };

  const handleHowToPlayClick = () => {
    setShowHowToPlayModal(true);
  };

  const handleCloseHowToPlayModal = () => {
    setShowHowToPlayModal(false);
  };

  const handleSettingsClick = () => {
    setShowSettingsModal(true);
  };

  const handleCloseSettingsModal = () => {
    setShowSettingsModal(false);
  };

  const toggleTheme = () => {
    setIsLightTheme(!isLightTheme);
  };

  const logoText = "Cupller";

  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  useEffect(() => {
    const spans = document.querySelectorAll(".header-logo span");
    let index = 0;

    const interval = setInterval(() => {
      if (spans.length > 0) {
        // Reset all spans to default color
        spans.forEach((span) => span.classList.remove("random-color"));

        // Select a random color for the current index
        const randomColor = getRandomColor();
        (spans[index] as HTMLElement).style.setProperty(
          "--random-color",
          `var(${randomColor})`
        );
        spans[index].classList.add("random-color");

        // Move to the next index
        index = (index + 1) % spans.length;
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [colors]);

  useEffect(() => {
    const spans = document.querySelectorAll(".header-logo span");

    const handleMouseOver = (event: Event) => {
      const span = event.target as HTMLElement;
      const randomHoverColor = getRandomColor();
      span.style.setProperty(
        "--random-hover-color",
        `var(${randomHoverColor})`
      );
    };

    spans.forEach((span) => {
      span.addEventListener("mouseover", handleMouseOver);
      span.addEventListener("click", (e) => e.preventDefault());
    });

    return () => {
      spans.forEach((span) => {
        span.removeEventListener("mouseover", handleMouseOver);
        span.removeEventListener("click", (e) => e.preventDefault());
      });
    };
  }, [colors]);

  useEffect(() => {
    if (gameOver) {
      // Disable the buttons when the game is over
      setDisableButtons(true);

      const timeoutId = setTimeout(() => {
        // Reset the disabled state after the timeout
        setDisableButtons(false);
      }, 2000);

      // Clear the timeout and reset the state if the game state changes
      return () => {
        clearTimeout(timeoutId);
        setDisableButtons(false);
      };
    }
  }, [gameOver]);

  return (
    <header className="header">
      <div className="ko-fi-image">
        <a
          href="https://ko-fi.com/C0C8WSORK"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{ border: "0px", height: "40px" }}
            src="https://storage.ko-fi.com/cdn/kofi1.png?v=3"
            alt="Buy Me a Coffee at ko-fi.com"
          />
        </a>
      </div>

      <div className="header-logo">
        {logoText.split("").map((char, index) => (
          <span key={index}>{char}</span>
        ))}
      </div>
      <div className="button-container">
        <button
          className="header-button"
          onClick={() => {
            resetSelectedItem();
            handleHowToPlayClick();
          }}
          disabled={disableButtons}
        >
          <img
            src={isLightTheme ? howToPlayLightIcon : howToPlayIcon}
            alt="How to Play icon"
          />
        </button>
        <button
          className="header-button"
          onClick={() => {
            resetSelectedItem();
            handleStatisticsClick();
          }}
          disabled={disableButtons}
        >
          <img
            src={isLightTheme ? statisticsLightIcon : statisticsIcon}
            alt="Statistics icon"
          />
        </button>
        <button
          className="header-button"
          onClick={() => {
            resetSelectedItem();
            handleSettingsClick();
          }}
          disabled={disableButtons}
        >
          <img
            src={isLightTheme ? settingsLightIcon : settingsIcon}
            alt="Settings icon"
          />
        </button>
      </div>

      <SettingsModal
        isOpen={showSettingsModal}
        onClose={handleCloseSettingsModal}
        toggleTheme={toggleTheme}
        isLightTheme={isLightTheme}
        toggleGameMode={toggleGameMode}
        isHardMode={isHardMode}
        isHighContrast={isHighContrast}
        toggleHighContrastMode={toggleHighContrastMode}
      />

      <StatisticsModal
        isOpen={showStatisticsModal}
        onClose={handleCloseModal}
        gameStatistics={gameStatistics}
        winningDistribution={winningDistribution}
        gameOver={gameOver}
        lastGuessIndex={lastGuessIndex}
      />

      <HowToPlayModal
        isOpen={showHowToPlayModal}
        onClose={handleCloseHowToPlayModal}
        isHighContrast={isHighContrast}
      />
    </header>
  );
};

export default Header;
