import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Item,
  allCupItems,
  allHighContrastCupItems,
} from "../../utils/allCupItems";
import {
  loadAttemptHistory,
  saveAttemptHistory,
} from "../../utils/attemptHistory";
import { loadDailySolution } from "../../utils/dailySolution";
import {
  getMaxNumberOfTries,
  updateMaxNumberOfTries,
} from "../../utils/gameConfig";
import shuffleCupItems from "../../utils/shuffleCupItems";
import SolutionSequence from "../SolutionSequence";
import Header from "../header/Header";
import Consent from "../modals/consent/Consent";
import HowToPlayModal from "../modals/howToPlay/HowToPlay";
import StatisticsModal from "../modals/statisticsModal/StatisticsModal";
import SortableItem from "../sortableItem/SortableItem";
import "./GameBoard.css";

const GameBoard: React.FC = () => {
  const getHighContrastMode = () => {
    return JSON.parse(localStorage.getItem("highContrast") || "0") === 1;
  };

  const [isHighContrast, setIsHighContrast] = useState(getHighContrastMode);
  const itemsSource = isHighContrast ? allHighContrastCupItems : allCupItems;

  // Load the daily solution
  const { solution: dailySolutionSequence, isNew } =
    loadDailySolution(itemsSource);

  // Make a copy of the daily solution sequence
  const toBeShuffledDailySolutionSequence = [...dailySolutionSequence];

  const startingSequence: Item[] = shuffleCupItems(
    toBeShuffledDailySolutionSequence
  );
  const itemsSolution: number[] = dailySolutionSequence.map((item) => item.id);

  const [items, setItems] = useState<Item[]>(startingSequence);
  const [itemOrder, setItemOrder] = useState<number[]>(
    startingSequence.map((item) => item.id)
  );
  const [attemptHistory, setAttemptHistory] =
    useState<{ order: number[]; correctPositions: number }[]>(
      loadAttemptHistory
    );
  const [activeId, setActiveId] = useState<number | null>(null);
  const [attempts, setAttempts] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>("");
  const [correctPositions, setCorrectPositions] = useState<number>(0);
  const [failedToGuess, setFailedToGuess] = useState<boolean>(false);
  const [showHowToPlayModal, setShowHowToPlayModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [consent, setConsent] = useState<number>(
    JSON.parse(localStorage.getItem("consent") || "0")
  );
  const [gameOver, setGameOver] = useState<boolean>(
    JSON.parse(localStorage.getItem("gameOver") || "false")
  );
  // Initialize game statistics with default values or load from local storage
  const [gameStatistics, setGameStatistics] = useState(() => {
    const savedStats = localStorage.getItem("gameStatistics");
    if (savedStats !== null) {
      return JSON.parse(savedStats);
    } else {
      return {
        totalGames: 0,
        gamesWon: 0,
        winRate: 0,
        averageScore: 0,
      };
    }
  });
  const [statsUpdated, setStatsUpdated] = useState<boolean>(
    JSON.parse(localStorage.getItem("statsUpdated") || "false")
  );
  const [winningDistribution, setWinningDistribution] = useState<number[]>(
    () => {
      const savedDistribution = localStorage.getItem("winningDistribution");
      if (savedDistribution !== null) {
        const parsedDistribution = JSON.parse(savedDistribution);
        if (parsedDistribution.length < getMaxNumberOfTries()) {
          return Array(getMaxNumberOfTries()).fill(0);
        }
        return parsedDistribution;
      } else {
        return Array(getMaxNumberOfTries()).fill(0);
      }
    }
  );

  const [statisticsModalVisible, setStatisticsModalVisible] =
    useState<boolean>(false);
  const [touchStart, setTouchStart] = useState<{
    id: number;
    x: number;
    y: number;
  } | null>(null);
  const [previouslyTappedItem, setPreviouslyTappedItem] = useState<
    number | null
  >(null);

  const getGameMode = () => {
    const savedGameMode = localStorage.getItem("mode");
    if (savedGameMode) {
      return savedGameMode === "hard";
    } else {
      localStorage.setItem("mode", "normal");
      return false;
    }
  };

  const [isHardMode, setIsHardMode] = useState(getGameMode);
  const [maxNumberOfTries, setMaxNumberOfTries] = useState<number>(
    getMaxNumberOfTries()
  );

  useEffect(() => {
    // Apply animation to the first li element with index 0
    const firstLi = document.querySelector(
      ".attempts-history-container li:first-child .attempt-result-number"
    );
    if (firstLi) {
      firstLi.classList.add("animate");
      setTimeout(() => {
        firstLi.classList.remove("animate");
      }, 1000);
    }
  }, [attemptHistory]);

  useEffect(() => {
    const itemsSource = isHighContrast ? allHighContrastCupItems : allCupItems;
    setItems((prevItems) =>
      prevItems.map((item) => {
        const newItem = itemsSource.find(
          (sourceItem) => sourceItem.id === item.id
        );
        return newItem || item;
      })
    );
  }, [isHighContrast]);

  useEffect(() => {
    // Update localStorage with the current mode
    localStorage.setItem("mode", isHardMode ? "hard" : "normal");

    // Update maxNumberOfTries based on the current mode
    const mode = isHardMode ? "hard" : "normal";
    updateMaxNumberOfTries(mode);

    // Set maxNumberOfTries state based on the updated value
    setMaxNumberOfTries(getMaxNumberOfTries());
  }, [isHardMode]);

  const toggleGameMode = () => {
    setIsHardMode(!isHardMode);
  };

  const boundingContainerRef = useRef<HTMLDivElement>(null);
  const shouldDisplayHowToPlay = consent === 1;

  useEffect(() => {
    if (gameOver) {
      const timeoutId = setTimeout(() => {
        setStatisticsModalVisible(true);
      }, 2000); // 2 seconds delay

      return () => clearTimeout(timeoutId); // Clear the timeout if component unmounts or if gameOver state changes before timeout
    }
  }, [gameOver]);

  useEffect(() => {
    // Load attempt history from local storage
    const attemptHistory = loadAttemptHistory();
    const savedAttempts = attemptHistory.length;
    setAttempts(savedAttempts);
  }, []);

  useEffect(() => {
    if (gameOver && !statsUpdated) {
      calculateGameStatistics();
      setStatsUpdated(true);
      localStorage.setItem("statsUpdated", JSON.stringify(true));
    }
  }, [gameOver, statsUpdated]);

  useEffect(() => {
    if (isNew) {
      setAttemptHistory([]);
      setAttempts(0);
      setGameOver(false);
      setStatsUpdated(false);
      localStorage.setItem("statsUpdated", JSON.stringify(false));
      localStorage.setItem("gameOver", JSON.stringify(false));
      localStorage.removeItem("attemptHistory");

      // Shuffle the starting sequence if isNew is true
      const toBeShuffledDailySolutionSequence = [...dailySolutionSequence];
      const startingSequence: Item[] = shuffleCupItems(
        toBeShuffledDailySolutionSequence
      );
      setItems(startingSequence);
      setItemOrder(startingSequence.map((item) => item.id));
    }
  }, [isNew, dailySolutionSequence]);

  useEffect(() => {
    // Load game statistics from local storage
    const savedGameStatistics = localStorage.getItem("gameStatistics");
    if (savedGameStatistics) {
      setGameStatistics(JSON.parse(savedGameStatistics));
    } else {
      // Game statistics not found, show how to play modal
      setShowHowToPlayModal(true);
    }

    // Set initial consent state
    const initialConsent = JSON.parse(localStorage.getItem("consent") || "0");
    setConsent(initialConsent);

    // Initialize consent in local storage if not set already
    if (!localStorage.getItem("consent")) {
      localStorage.setItem("consent", JSON.stringify(initialConsent));
    }
  }, []);

  // Determine whether to animate the items based on game over state
  const animateItems = gameOver;

  // Extract the latest correctPositions value from the attemptHistory
  const latestCorrectPositions =
    attemptHistory.length > 0
      ? attemptHistory[attemptHistory.length - 1].correctPositions
      : 0;

  // Extract the latest order array
  const latestOrder =
    attemptHistory.length > 0
      ? attemptHistory[attemptHistory.length - 1].order
      : [];

  const handleCloseModal = () => {
    setStatisticsModalVisible(false);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 10,
        tolerance: 10,
      },
    })
  );

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id); // Store the ID of the active item
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setItemOrder((prevOrder) => {
        const oldIndex = prevOrder.indexOf(Number(active.id));
        const newIndex = prevOrder.indexOf(Number(over.id));
        const newOrder = arrayMove(prevOrder, oldIndex, newIndex);
        return newOrder;
      });

      setItems((prevItems) => {
        const oldIndex = prevItems.findIndex((item) => item.id === active.id);
        const newIndex = prevItems.findIndex((item) => item.id === over.id);
        return arrayMove(prevItems, oldIndex, newIndex);
      });
    }

    setActiveId(null); // Reset active ID after dragging
  };

  const getItemById = (id: number): Item | undefined => {
    return items.find((item) => item.id === id);
  };

  const checkOrder = () => {
    setAttempts(attempts + 1);
    let correctPositions = 0;
    itemOrder.forEach((id, index) => {
      if (id === itemsSolution[index]) {
        correctPositions += 1;
      }
    });

    setCorrectPositions(correctPositions);

    setAttemptHistory([
      ...attemptHistory,
      { order: [...itemOrder], correctPositions },
    ]);

    saveAttemptHistory([
      ...attemptHistory,
      { order: [...itemOrder], correctPositions },
    ]);

    if (correctPositions === itemsSolution.length) {
      setFeedback("Awesome, you've matched all the cups!");
      setGameOver(true);
      localStorage.setItem("gameOver", JSON.stringify(true));

      // Introduce a 3-second delay before setting gameOver and displaying the modal
      setTimeout(() => {
        setStatisticsModalVisible(true);
      }, 2000);
      return;
    }

    if (attempts >= getMaxNumberOfTries() - 1) {
      setFailedToGuess(true);
      setGameOver(true);
      localStorage.setItem("gameOver", JSON.stringify(true));

      // Introduce a 3-second delay before setting gameOver and displaying the modal
      setTimeout(() => {
        setStatisticsModalVisible(true);
      }, 2000);
      return;
    }

    if (correctPositions === 1) {
      setFeedback(`${correctPositions} matched cup! 🤔`);
    } else {
      setFeedback(`${correctPositions} matched cups! 🤔`);
    }
  };

  const calculateGameStatistics = () => {
    const newGameStats = { ...gameStatistics };
    const newDistribution = [...winningDistribution];

    // Check if the game is over and if the game was won
    if (
      (gameOver && correctPositions === itemsSolution.length) ||
      failedToGuess
    ) {
      newGameStats.totalGames += 1;

      if (correctPositions === itemsSolution.length) {
        // Update win count
        newGameStats.gamesWon += 1;

        const attemptsIndex = attempts - 1; // Array indexes are 0-based, attempts start from 1

        // Increment the corresponding index in the winning distribution array
        newDistribution[attemptsIndex] += 1;

        // Update the winning distribution state
        setWinningDistribution(newDistribution);

        // Calculate the average score
        const averageScore = calculateAverageScore(newDistribution);
        newGameStats.averageScore = averageScore;
      }

      // Set statsUpdated to true to indicate that statistics have been updated for the current day
      setStatsUpdated(true);
      localStorage.setItem("statsUpdated", JSON.stringify(true));
    }

    // Update win rate
    newGameStats.winRate = Math.round(
      (newGameStats.gamesWon / newGameStats.totalGames) * 100
    );

    // Set the updated game statistics
    setGameStatistics(newGameStats);

    // Save game statistics to local storage
    localStorage.setItem("gameStatistics", JSON.stringify(newGameStats));
    localStorage.setItem(
      "winningDistribution",
      JSON.stringify(newDistribution)
    );
  };

  // Calculate the average score based on winningDistribution
  const calculateAverageScore = (winningDistribution: number[]) => {
    let sum = 0;
    let totalGames = 0;

    for (let index = 0; index < winningDistribution.length; index++) {
      sum += winningDistribution[index] * (index + 1); // Multiply index + 1 since first index is 0 by its frequency
      totalGames += winningDistribution[index];
    }

    if (totalGames === 0) return 0;

    const averageScore = sum / totalGames;
    return parseFloat(averageScore.toFixed(2));
  };

  const lastGuessIndex = attemptHistory.findIndex(
    (attempt) => attempt.correctPositions === itemsSolution.length
  );

  const handleItemTouchStart = (id: number, event: React.TouchEvent) => {
    if (gameOver) return;
    const touch = event.touches[0];
    setTouchStart({ id, x: touch.clientX, y: touch.clientY });
  };

  const handleItemTouchEnd = (id: number, event: React.TouchEvent) => {
    if (gameOver) return;
    event.preventDefault(); // Prevent the default action of the touch event
    if (!touchStart) return;

    const touch = event.changedTouches[0];
    const dx = touch.clientX - touchStart.x;
    const dy = touch.clientY - touchStart.y;

    // Define a threshold to distinguish between a tap and a drag
    const threshold = 10; // Adjust threshold as needed
    if (Math.abs(dx) < threshold && Math.abs(dy) < threshold) {
      // Treat it as a tap
      handleTap(id);
    }

    setTouchStart(null);
  };

  const handleTap = (id: number) => {
    if (gameOver) return;

    if (selectedItem === null) {
      // If no item is selected, select the tapped item
      setSelectedItem(id);
    } else if (selectedItem === id || previouslyTappedItem === id) {
      // If the tapped item is already selected or was previously tapped, deselect it
      setSelectedItem(null);
      setPreviouslyTappedItem(null);
    } else {
      // If a different item is already selected, swap the items
      const newOrder = [...itemOrder];
      const selectedIndex = newOrder.indexOf(selectedItem);
      const tappedIndex = newOrder.indexOf(id);

      // Swap only the selected and tapped items
      [newOrder[selectedIndex], newOrder[tappedIndex]] = [
        newOrder[tappedIndex],
        newOrder[selectedIndex],
      ];

      setItemOrder(newOrder);
      setItems((prevItems) => {
        const newItems = [...prevItems];
        const selectedIndex = newItems.findIndex(
          (item) => item.id === selectedItem
        );
        const tappedIndex = newItems.findIndex((item) => item.id === id);

        // Swap only the selected and tapped items
        [newItems[selectedIndex], newItems[tappedIndex]] = [
          newItems[tappedIndex],
          newItems[selectedIndex],
        ];

        return newItems;
      });

      setSelectedItem(null);
    }

    // Update the previously tapped item regardless of the action taken
    setPreviouslyTappedItem(id);
  };

  const resetSelectedItem = () => {
    setSelectedItem(null);
  };

  const toggleHighContrastMode = () => {
    setIsHighContrast((prev) => {
      const newMode = !prev;
      if (newMode) {
        localStorage.setItem("highContrast", "1");
      } else {
        localStorage.removeItem("highContrast");
      }
      return newMode;
    });
  };

  return (
    <>
      <Helmet>
        <title>Cupller</title>
        <meta
          name="keywords"
          content="Cup Match Challenge, Match The Cup Challenge, Cupller, Daily Challenge, Statistics, How To Play, Guess The Solution, Match The Cups, Cup Shuffle, Cup Matching, Game, React, Puzzle, Memory Game"
        />
        <meta property="og:title" content="Cupller" />
        <meta
          property="og:description"
          content="A daily cup matching challenge! Guess the cup sequence in 8 attempts."
        />
        <meta property="og:image" content="https://cupller.com/og-image.png" />
        <meta property="og:url" content="https://cupller.com" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Cupller" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Cupller" />
        <meta
          name="twitter:description"
          content="A daily cup matching challenge! Guess the cup sequence in 8 attempts."
        />
        <meta name="twitter:image" content="https://cupller.com/og-image.png" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Game",
              "name": "Cupller",
              "description": "A daily cup matching challenge! Guess the cup sequence in 8 attempts.",
              "keywords": "Cup Match Challenge, Match The Cup Challenge, Cupller, Daily Challenge, Statistics, How To Play, Guess The Solution, Match The Cups, Cup Shuffle, Cup Matching, Game, React, Puzzle, Memory Game",
              "genre": ["Puzzle", "Casual", "Memory", "Attention"],
              "publisher": {
                "@type": "Person",
                "name": "Tiberiu Matei"
              },
              "url": "https://cupller.com"
            }
          `}
        </script>
      </Helmet>

      <div ref={boundingContainerRef} className="game-board">
        <Header
          gameStatistics={gameStatistics}
          winningDistribution={winningDistribution}
          gameOver={gameOver}
          lastGuessIndex={lastGuessIndex}
          resetSelectedItem={resetSelectedItem}
          toggleGameMode={toggleGameMode}
          isHardMode={isHardMode}
          isHighContrast={isHighContrast}
          toggleHighContrastMode={toggleHighContrastMode}
        />

        {selectedItem === null ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={gameOver ? toBeShuffledDailySolutionSequence : items}
              strategy={horizontalListSortingStrategy}
            >
              <div className="sortable-container">
                {(gameOver ? toBeShuffledDailySolutionSequence : items).map(
                  (item) => (
                    <div
                      key={item.id}
                      className={`sortable-item ${
                        item.id === Number(activeId) ? "active" : ""
                      } ${gameOver ? "disabled" : ""}`}
                      onClick={() => handleTap(item.id)}
                      onTouchStart={(event) =>
                        handleItemTouchStart(item.id, event)
                      }
                      onTouchEnd={(event) => handleItemTouchEnd(item.id, event)}
                    >
                      <SortableItem
                        id={item.id}
                        content={item.content}
                        animate={animateItems}
                        isSelected={item.id === selectedItem}
                      />
                    </div>
                  )
                )}
              </div>
            </SortableContext>
          </DndContext>
        ) : (
          // Render items without DndContext when an item is selected
          <div className="sortable-container">
            {items.map((item) => (
              <div
                key={item.id}
                className={`sortable-item ${
                  item.id === Number(activeId) ? "active" : ""
                }`}
                onClick={() => handleTap(item.id)}
                onTouchStart={(event) => handleItemTouchStart(item.id, event)}
                onTouchEnd={(event) => handleItemTouchEnd(item.id, event)}
              >
                <SortableItem
                  id={item.id}
                  content={item.content}
                  animate={animateItems}
                  isSelected={item.id === selectedItem}
                />
              </div>
            ))}
          </div>
        )}

        <div className="guess-details">
          <div className="attempts-info">
            Attempt{" "}
            <span>
              {attempts} / {maxNumberOfTries}
            </span>
          </div>
          {gameOver ? (
            <>
              {latestCorrectPositions === 5 ? (
                <div className="game-won">
                  <p>Awesome, you've matched all the cups! 🎉</p>
                  <div className="solution-sequence">
                    <SolutionSequence
                      dailySolutionSequence={dailySolutionSequence}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {
                    <div className="game-lost">
                      <p>Game over! 😔 The correct sequence is:</p>
                      <div className="solution-sequence">
                        <SolutionSequence
                          dailySolutionSequence={dailySolutionSequence}
                        />
                      </div>
                    </div>
                  }
                </>
              )}
            </>
          ) : (
            !failedToGuess && <div className="guess-feedback">{feedback}</div>
          )}
        </div>

        <div className="match-button-container">
          <button
            className="match-button"
            onClick={checkOrder}
            disabled={
              attempts >= getMaxNumberOfTries() ||
              correctPositions === itemsSolution.length ||
              gameOver
            }
          >
            MATCH
          </button>
        </div>

        <div className="attempts-history-container">
          <ul>
            {attemptHistory
              .slice()
              .reverse()
              .map((attempt, index) => {
                const attemptNumber = attemptHistory.length - index;
                return (
                  <li key={index}>
                    <div className="attempt-stats">
                      <div className="attempt-content">
                        <div className="attempt-number-wrapper">
                          <p className="attempt-number">{`#${attemptNumber}`}</p>
                          <p className="attempt-text">Attempt</p>
                        </div>

                        <div className="attempt-result-wrapper">
                          <p
                            className={`attempt-result-number ${
                              index === 0 ? "latest-attempt" : ""
                            }`}
                          >
                            {attempt.correctPositions}
                          </p>
                          <p className="attempt-result-text">
                            {attempt.correctPositions === 1
                              ? "Match"
                              : "Matches"}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="attempt-items">
                      {attempt.order.map((id) => {
                        const item = getItemById(id);
                        return item ? (
                          <img
                            key={id}
                            src={item.content}
                            alt={`Cup ${id}`}
                            style={{
                              width: "50px",
                              height: "50px",
                              margin: "0 5px",
                            }}
                          />
                        ) : null;
                      })}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <StatisticsModal
        isOpen={statisticsModalVisible}
        onClose={handleCloseModal}
        gameStatistics={gameStatistics}
        winningDistribution={winningDistribution}
        gameOver={gameOver}
        lastGuessIndex={lastGuessIndex}
      />
      {consent === 0 && <Consent onClose={() => setConsent(1)} />}
      {shouldDisplayHowToPlay && (
        <HowToPlayModal
          isOpen={showHowToPlayModal}
          onClose={() => setShowHowToPlayModal(false)}
          isHighContrast={isHighContrast}
        />
      )}
    </>
  );
};

export default GameBoard;
