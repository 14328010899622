import React, { useEffect } from "react";
import {
  Item,
  allCupItems,
  allHighContrastCupItems,
} from "../../../utils/allCupItems";
import { toggleBodyOverflow } from "../../../utils/toggleBodyOverflow";
import "./HowToPlay.css";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  isHighContrast: boolean;
}

const HowToPlayModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  isHighContrast,
}) => {
  useEffect(() => {
    toggleBodyOverflow(isOpen);
    return () => toggleBodyOverflow(false);
  }, [isOpen]);

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  const generateFixedCupSequence = (
    sequenceLength: number,
    allCupItems: Item[]
  ): Item[] => {
    return allCupItems.slice(0, sequenceLength);
  };

  const cupSequenceLength = 5; // Set the desired length of the cup sequence
  const cupSequence: Item[] = isHighContrast
    ? generateFixedCupSequence(cupSequenceLength, allHighContrastCupItems)
    : generateFixedCupSequence(cupSequenceLength, allCupItems);

  return (
    <div className="modal-overlay-htp" onClick={handleOverlayClick}>
      <div className="modal-htp">
        <button className="x-button" onClick={onClose}>
          X
        </button>
        <div className="modal-content-htp">
          <h2>HOW TO PLAY</h2>
        </div>
        <div className="cupller-main-text">
          <span>Cupller</span> - a daily cup matching challenge!
        </div>
        <div className="cupller-objective">
          <p>
            Every day, a new cup sequence is generated. Tap a cup and swap with
            another or drag and drop in order to guess the cup sequence in{" "}
            <span>8 attempts</span>.
          </p>
        </div>
        <div className="cupller-example-title">EXAMPLE</div>
        <div className="cup-sequence">
          {cupSequence.map((cup) => (
            <img
              style={{
                width: "50px",
                height: "50px",
                margin: "0 5px",
              }}
              key={cup.id}
              src={cup.content}
              alt={`Cup ${cup.id}`}
            />
          ))}
        </div>
        <div className="cupller-attempt-example">
          <p>
            Attempt <span>2 / 8</span>
          </p>
        </div>
        <div className="cupller-match-example">
          <p>2 matched cups! 🤔</p>
        </div>
        <div className="cupller-text">
          2 cups are matching the solution positions and you have 6 more
          attempts to guess the solution pattern. Keep an eye out on the Attempt
          History as it will help you figure out the solution pattern.
        </div>
        <div className="developer">
          Game developed by{" "}
          <a
            href="https://github.com/TiberiuMatei"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tiberiu Matei
          </a>
        </div>
      </div>
    </div>
  );
};

export default HowToPlayModal;
