// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.distribution-container {
  width: 100%;
  text-align: left;
}

.distribution-title {
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.5rem;
  color: var(--primary-text);
  cursor: default;
  font-weight: var(--nunito-bold);
  margin: 20px 0px;
  letter-spacing: 5px;
}

.bars-container {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 10px 10px 10px 30px;
}

.bars-span {
  width: 30px;
}

@media screen and (max-width: 700px) {
  .distribution-title {
    font-size: 1rem;
    padding: 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/distributionChart/DistributionChart.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,sCAAsC;EACtC,iBAAiB;EACjB,0BAA0B;EAC1B,eAAe;EACf,+BAA+B;EAC/B,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,eAAe;IACf,YAAY;EACd;AACF","sourcesContent":[".distribution-container {\r\n  width: 100%;\r\n  text-align: left;\r\n}\r\n\r\n.distribution-title {\r\n  text-align: center;\r\n  font-family: \"Nunito Sans\", sans-serif;\r\n  font-size: 1.5rem;\r\n  color: var(--primary-text);\r\n  cursor: default;\r\n  font-weight: var(--nunito-bold);\r\n  margin: 20px 0px;\r\n  letter-spacing: 5px;\r\n}\r\n\r\n.bars-container {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: left;\r\n  margin: 10px 10px 10px 30px;\r\n}\r\n\r\n.bars-span {\r\n  width: 30px;\r\n}\r\n\r\n@media screen and (max-width: 700px) {\r\n  .distribution-title {\r\n    font-size: 1rem;\r\n    padding: 0px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
